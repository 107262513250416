import Swal from 'sweetalert2';

export const showMessage = (msg:any, color:any, position = 'top-end', showCloseButton = true, closeButtonHtml = '', duration = 3000) => {
    const toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: duration,
      showCloseButton: showCloseButton,
      customClass: {
        popup: `color-`+color
      },
    });
    toast.fire({
      title: msg,
    });
  };
